import * as React from 'react';
import Link from 'next/link';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import { ProductSummaryCardViewProps } from 'lib/themes/themes';

import config from 'config';
import baseStyles from '../base.module.css';
import styles from './ProductSummaryCardView.module.css';
import { getWeekdayText } from 'lib/util/getWeekdayText';
import { useCurrencyFormatter, getDisplayPrice } from 'hooks/useCurrencyFormatter';
import { useCustomerLedgerEnabled } from 'hooks/useCustomerLedgerEnabled';

const ProductSummaryCardView = ({ product, productUrl }: ProductSummaryCardViewProps) => {
  const { t } = useTranslation();

  const currencyFormatter = useCurrencyFormatter();
  const customerLedgerEnabled = useCustomerLedgerEnabled();

  const hasPromotion = product.bestDiscountGross != '' || product.bestDiscountPercent != 0;

  return (
    <Link href={productUrl}>
      <div className={styles['item__li__pic']}>
        <img src={product.imageURL} />
        <div className={styles['c-card__pic__labelframe']}>
          {customerLedgerEnabled && product?.shouldShowOnlyForLoggedInCustomer && (
            <div className={styles['c-card__pic__labelframe__recommend']}>
              <div>
                <img src="/static/images/ic_recommend_white.svg" alt="recommend" />
              </div>
              <p>{t('Members Only')}</p>
            </div>
          )}
        </div>
        <div className={styles['item__li__sub']}>
          <div className={styles['item__li__sub__tag']}>
            {product.tags.map((tag) => (
              <p key={tag} className={baseStyles['color-txt']}>
                {tag}
              </p>
            ))}
          </div>
          <div className={clsx(styles['item__li__sub__erea'], baseStyles['color-bg'])}>
            <svg
              className={baseStyles['color-fill']}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
            </svg>
            <p className={baseStyles['color-sub--txt']}>{product.location}</p>
            <svg
              className={baseStyles['color-fill']}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
            >
              <path d="M0,32V0H32A32,32,0,0,0,0,32Z" />
            </svg>
          </div>
        </div>
      </div>
      <div className={styles['item__li__info']}>
        <Box display="flex">
          <h2 className={clsx(styles['item__li__info__ttl'], 'maincolor-txt')}>{product.name}</h2>
          <Box ml="auto" mt={1}>
            {product.reviewCount != null && product.reviewCount != 0 && (
              <div className="c-card__header__star">
                <img src="/static/images/ic_star_on.svg" alt="star" />
                <span>{(product.reviewRating ?? 0).toFixed(1)}</span>
                <span>({product.reviewCount})</span>
              </div>
            )}
          </Box>
        </Box>
        <p className={styles['item__li__info__desc']}>{product.description}</p>
        <div className={styles['item__li__info__bottom']}>
          <p className={styles['item__li__info__bottom__week']}>
            {t('Days of week: {{operatingDaysText}}', {
              operatingDaysText: product.operatingWeekdays
                .map((weekday) => getWeekdayText(weekday, t))
                .join(','),
            })}
          </p>
          {config.enablePromotionPromotionSpecificationChanges ? (
            <div style={{ display: 'block' }}>
              {hasPromotion && (
                <p style={{ textDecoration: 'line-through', textAlign: 'right' }}>
                  {currencyFormatter.format(product.originalLowPrice)}
                </p>
              )}
              <p
                className={clsx(
                  styles['item__li__info__bottom__price'],
                  baseStyles['color-main--txt']
                )}
              >
                {getDisplayPrice(currencyFormatter.format(product.lowPrice), product)}
              </p>
            </div>
          ) : (
            <p
              className={clsx(
                styles['item__li__info__bottom__price'],
                baseStyles['color-main--txt']
              )}
            >
              {currencyFormatter.format(product.lowPrice)}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ProductSummaryCardView;
